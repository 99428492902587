@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'variables';

/* For use in src/lib/core/theming/_palette.scss */
$md-yellow: (
  50 : #fff,
  100 : #fff,
  200 : #fff5ca,
  300 : #fee883,
  400 : #fee364,
  500 : #fedd46,
  600 : #fed728,
  700 : #fed209,
  800 : #e7be01,
  900 : #c8a501,
  A100 : #fff,
  A200 : #fff9de,
  A400 : #ffe778,
  A700 : #ffe25e,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);
/* For use in src/lib/core/theming/_palette.scss */

$md-darkred: (
  50 : #f7e0e1,
  100 : #ecb3b4,
  200 : #df8082,
  300 : #d24d50,
  400 : #c8262b,
  500 : #be0005,
  600 : #b80004,
  700 : #af0004,
  800 : #a70003,
  900 : #990001,
  A100 : #ffc4c4,
  A200 : #ff9191,
  A400 : #ff5e5e,
  A700 : #ff4545,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #fff,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #fff,
  )
);

$md-accent-green: (
  50 : #e0f2e2,
  100 : #b3e0b6,
  200 : #80cb85,
  300 : #4db654,
  400 : #26a62f,
  500 : #00960a,
  600 : #008e09,
  700 : #008307,
  800 : #007905,
  900 : #006803,
  A100 : #97ff97,
  A200 : #64ff64,
  A400 : #31ff31,
  A700 : #18ff18,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);
// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$my-theme-primary: mat.define-palette($md-yellow, 700, 300, 900);
$my-theme-accent: mat.define-palette($md-accent-green);
$my-theme-warn: mat.define-palette($md-darkred, A400);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$my-theme: mat.define-light-theme($my-theme-primary,
  $my-theme-accent,
  $my-theme-warn);


$custom-typography: mat.define-typography-config($font-family: '"Titillium Web", sans-serif',
$headline: mat.define-typography-level(64px, 76.8px, 600));

@include mat.core($custom-typography);

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  @include mat.all-component-themes($my-theme);
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.spb-logo {
  align-self: center;
  background-color: $spb-yellow;
  border-radius: 5px;
  color: #000;
  font-family: 'EB Garamond', serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1px;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

.spb-logo:hover {
  cursor: pointer;
}


@media screen and (min-width: $spb-max-width) {
  .spb-holder {
    margin: 0 auto;
    min-width: $spb-max-width;
    padding-left: 45px;
    width: 0;
    // border: 2px solid red;
  }
}

@media screen and (max-width: $spb-max-width) {
  .spb-holder {
    padding-left: 45px;
    // padding-right: 33%;
    padding-right: 45px;
    // border: 2px solid blue;
  }
}

@media screen and (max-width: $spb-small) {
  .spb-holder {
    margin: 0 auto;
    padding: initial;
    width: 95%;
    // border: 2px solid greenyellow;
  }
}


/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}
