$spb-yellow: #fedd46;
$spb-green: #bbdfd0;
$spb-grey: #a9a9a9;
$spb-pink: #edd7cc;
$spb-blue: #d7e9f5;

$spb-red: #be0005;

$spb-small: 766px;
$spb-medium: 996px;

$spb-max-width: 1170px;
